import styles from '../style/home/WhatSetsUsApart.module.css'
export function WhatSetsUsApart(){
    return(
        <section className="section bgGray whatSets">
            <div className="container">
                <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20">What Sets Us Apart?</h2>
                <p className="subHeading textCenter mb46 font16 fontSM14">Sublime Designs. Prompt Services. Best Results</p>
                <div className={styles.setsPartList}>
                    <ul className={styles.whatSetsList}>
                        <li>Solutions</li>
                        <li>Dedicated Team</li>
                        <li>Vr Experience</li>
                        <li>Quality Assurance</li>
                        <li>Price</li>
                        <li>Hidden Charges</li>
                        <li>Support</li>
                    </ul>
                    <div className={styles.experinceList}>
                        <h3 className={`font20 textpink fbold mb16 fontSM12 textSMcenter`}>Interior Company Experience</h3>
                        <ul className={styles.setsList}>
                            <li><span>All Under One Roof</span>
                                <p className="textSMcenter">A one-stop destination to resolve all your queries</p>
                            </li>
                            <li><span>Dedicated Team of Designers</span> <p className="textSMcenter">Experts who chalk out innovative solutions</p></li>
                            <li><span>3D Visual Walkthroughs</span><p className="textSMcenter">Home Interior Designs in tandem with interactive 3D Visual Walkthroughs</p> </li>
                            <li><span>Periodic Inspections</span><p className="textSMcenter">Well-timed quality reviews at various stages of the project</p> </li>
                            <li><span>No False Claims</span><p className="textSMcenter">Lowest price guaranteed!</p></li>
                            <li><span>You Get What You See</span><p className="textSMcenter">No Hidden Charges</p></li>
                            <li><span>We’re Right by Your Side</span><p className="textSMcenter">Dedicated After Sales Support</p></li>
                        </ul>
                    </div>
                    <div className={styles.typicalExperience}>
                        <h3 className={`fbold font20 mb16 fontSM12 textSMcenter ${styles.subHeading}`}>Typical Experience</h3>
                        <ul className={styles.setsList}>
                            <li><span>Multiple Contractors &amp; Market Trips</span>
                                <p>Coordination with several intermediaries</p>
                            </li>
                            <li><span>In-eﬃcient Support</span> <p>No team to provide feasible results</p></li>
                            <li><span>No VR Walkthroughs</span><p>Absence of Advanced Technology support</p> </li>
                            <li><span>No Periodic Quality Checks</span><p>Usage of low-grade material to save cost</p> </li>
                            <li><span>Over promising &amp; Under Delivery</span><p>No price match guarantee</p></li>
                            <li><span>Overhead Charges</span><p>Unidentified costs lead to higher prices</p></li>
                            <li><span>No After-Sales Support</span><p>Customer service ends after the completion of the project</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}