import Image from 'next/image'
import style from '../../components/style/home/MediaCoverage.module.css'
import CustomSlideArrow from '../shared/customSlideArrow/CustomSlideArrow'
import { NavLink } from '../ui'
import media from '../../data/mediaCoverage.json'
import { IMediaCoverage } from '../../interfaces/IMediaCoverage';
import { staticLoader } from '../../lib/helpers/imageLoader'
function MediaCoverage() {
  return (
    <section className="section bgGray">
        <div className="container">
            <h2 className="heading font26 text222 f500 fontSM20 mb46"> Media Coverage</h2>
            <div className='posR' >
                <div className={style.mediaCoverage} id='mediaCoverage'>
                    {media && media.map((d:IMediaCoverage,idx:number) => 
                        <div className={`${style.tile} bgWhite`} key={idx}>
                            <figure className={style.tileFig} >
                                <Image src={`/assets/images/${d.image}.png`} 
                                       alt='media logo' 
                                       width={180}
                                       height={30}
                                       loader={staticLoader}
                                       className='imgFluid' 
                                       />
                            </figure>
                            <span className={`${style.desc} f600 text222 mb16`} >{d.subTxt}</span>
                            <NavLink className={style.viewFullArticle} href={`${d.link}`} target='_blank' rel="nofollow">
                                <span>view full article</span>
                                <em>                        
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m6 1 5 5-5 5M1 6h9.286H1z" stroke="#EB5976" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </em>
                            </NavLink>
                        </div>
                    )}
                </div>
                {media && media.length > 3 && 
                    <CustomSlideArrow slideId={'mediaCoverage'} slideTileWebWidth={290} slideTileMobWidth={290} />
                }
            </div>
        </div>
    </section>
  )
}
export default MediaCoverage