import React, { useState } from 'react'
import Image from "next/image";
import styles from '../style/home/HowItWorks.module.css';
import { useGlobalContext } from '@/lib/context';
const array = [1, 2, 3, 4, 5];
export function HowItWorks() {
    const { props:{isMobileView} } = useGlobalContext();
    const [active, setActive] = useState(0);

    const delay = 2500;
    React.useEffect(() => {
        setTimeout(
            () =>
                setActive((prevIndex) =>
                    prevIndex === array.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
    }, [active]);
    return (
        <section className={`${styles.howItWorkMain} section`}>
            <div className="container">
                <div className={styles.workBx} >
                    <div className={styles.workImg} >
                        {!isMobileView ?
                        <figure className={styles.stepImg} ><Image
                            src="/assets/images/we-work-web.webp"
                            height={316}
                            width={474}
                            alt='meet us'
                            className="imgFluid"
                        /></figure>: 
                        <figure className={styles.stepImg} ><Image
                            src="/assets/images/we-work-mob.webp"
                            height={218}
                            width={328}
                            alt='meet us'
                            className="imgFluid"
                        /></figure>
                        }
                    </div>
                    <div className={styles.workDetails} >
                        <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20">The Way We Work</h2>
                        <p className="subHeading textCenter mb24 font16 fontSM14">Five steps is all it takes to elevate your style of living.</p>
                        <div className='mb46' >
                            <ul className={styles.stepsBx} >
                                <li className={active === 0 ? styles.active : ''} >
                                    <span>1</span>
                                    <label>Meet Us</label>
                                </li>
                                <li className={active === 1 ? styles.active : ''} >
                                    <span>2</span>
                                    <label>Scope of Work</label>
                                </li>
                                <li className={active === 2 ? styles.active : ''} >
                                    <span>3</span>
                                    <label>Seal the Deal</label>
                                </li>
                                <li className={active === 3 ? styles.active : ''} >
                                    <span>4</span>
                                    <label>Execution</label>
                                </li>
                                <li className={active === 4 ? styles.active : ''} >
                                    <span>5</span>
                                    <label>Stamp of Approval</label>
                                </li>
                            </ul>
                        </div>
                        <ul className={styles.context} >
                            <li className={active === 0 ? styles.active : ''} >
                                <label>Meet Us</label>
                                <span>Let us know more about each other. We can meet over a cup of coffee or even online too. We&apos;d like to know what you&apos;re looking for</span>
                            </li>
                            <li className={active === 1 ? styles.active : ''} >
                                <label>Scope of Work</label>
                                <span>Now when you have made up your mind, it is the time to finalise the scope of work and talk about the essentials – materials and finishes, etc.</span>
                            </li>
                            <li className={active === 2 ? styles.active : ''} >
                                <label>Seal The Deal</label>
                                <span>Once finalised, you can seal the deal by paying a signing amount of 10%. Pay 50% of the order value to kickstart the process and the balance before handover.</span>
                            </li>
                            <li className={active === 3 ? styles.active : ''} >
                                <label>Execution</label>
                                <span>While we immediately begin work on your different orders, our quality control team keeps a strict check. We also keep you updated about the execution process.</span>
                            </li>
                            <li className={active === 4 ? styles.active : ''} >
                                <label>Stamp of Approval</label>
                                <span>Congratulations! We are done. You can now move into your dream home.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}